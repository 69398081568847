.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
#botonCopy {
  visibility: hidden;
}
#segundoForm {
  visibility: hidden;
}
.App-logo {
  position: static;
  width: 20vw;
  
}
.g-recaptcha {
  visibility: hidden; /* hide the captcha (Mostratlo cuando funcione correctamente) */
  margin: auto;
  width: 125px;
  position: relative;	
  padding-bottom: 50px;
  
}

body{
  background-color: #171717;
  background-image:url(./img/bck.svg) ;
  background-repeat: no-repeat;
  background-position: bottom right;
}

@media (max-width: 1000px) {
  * {
    overflow: hidden;
  }
  .login__input {
    width: 80vw !important; 
  }
  .login__selector {
    width: 80vw !important; 
  }
  .login__submit {
    width: 85vw !important; 
  }
 }
.App-header {

  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: white;  ;
  font-size: initial;
  font-style: italic;
  margin-top: 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css?family=Raleway:400,700');
















.login__field {
	padding: 20px 0px;

	position: relative;	

}

.login__icon {
	position: absolute;
	top: 30px;
	color: #7875B5;
}

.login__input {
  min-height: 40px;
  margin-bottom: 0px;
	border: none;
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	/*font-weight: 700;*/
	width: 462px;
	transition: .2s;
  font-family: Arial, Helvetica, sans-serif;
  font-size: x-large;
  color: white;
  padding: 10px;
}


.login__selector {
	border: none;
  color: white;
  background-color: #171717 !important; 
	border-bottom: 2px solid #D1D1D4;
	background: none;
	padding: 10px;
	padding-left: 24px;
	font-weight: 700;
	width: 480px;
	transition: .2s;
  margin-bottom: 60px;
  font-size: initial;

  
}

.login__input:active,
.login__input:focus,
.login__input:hover {
	outline: none;
	border-bottom-color: #6A679E;
}



.login__submit:active,
.login__submit:focus,
.login__submit:hover {
	border-color: #f35623;
	outline: none;
}




input[type="text"] {
    padding: 10px;
    /*border-radius: 5px;*/
    margin: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: x-large;
    color: white;
    background: none;
}

/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: rgb(255, 255, 255) !important;
}
/*Change background color of autofill*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.login__submit {
  padding: 25px 52px;
  border-style: solid;
  border-width: 1px;
  border-color: #f35623;
  border-radius: 2px;
  background-color: #f35623;
  transition: border-color 300ms ease, background-color 300ms cubic-bezier(0.65 , 0.2 , 0.25 , 1), transform 300ms ease, color 300ms ease;
  color: #171717;
  font-size: 16px;
  line-height: 1.111em;
  font-weight: 400;
  text-align: center center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  cursor: pointer;
  /*font-weight: bolder;*/
  width: 480px;
  
  /*background: #fff;
	font-size: 14px;
	
	padding: 16px 20px;
	
	border: 1px solid #D4D3E8;
	text-transform: uppercase;
  
	font-weight: 700;
	display: flex;
	align-items: center;
	width: 100%;
	color: black;
	box-shadow: 0px 2px 2px #f35623;
  
	cursor: pointer;
	transition: .2s;*/
}



button {
  border-style: solid;
  border-width: 1px;
  border-color: #f35623;
  border-radius: 2px;
  background-color: #f35623;
  transition: border-color 300ms ease, background-color 300ms cubic-bezier(0.65 , 0.2 , 0.25 , 1), transform 300ms ease, color 300ms ease;
  color: #171717;
  font-size: 16px;
  line-height: 1.111em;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  cursor: pointer;
  
    
  /*border-radius: 5px;
    padding: 10px;
    color: #fff;
    background-color: black;
    border-color: black;
    font-weight: bolder;
    align-items: center !important;
    text-align: center !important;
    cursor: pointer;
    margin-bottom: 10vh; */
}

/*Button to change the lenguage of the page*/
    
button:hover {
    text-decoration: none;
    color: black;
    background-color: #f35623;
    border-color: #f35623;
    -webkit-transform: translate3d(0, -3px, 0.01px);
    transform: translate3d(0, -3px, 0.01px);
}

/* Center the loader */
.loader {
  margin-left: auto !important;
  margin-right: auto !important;
}


/* Botones de idioma */
.en {
  background-color: transparent;
  border-color: transparent;
}

.es {
  background-color: transparent;
  border-color: transparent;
}
    
.login__submit_2 {
  padding: 0px 0px;
  border-style: solid;
  border-width: 1px;
  border-color: white;
  border-radius: 2px;
  margin-top: 20px;
  background-color: white;
  transition: border-color 300ms ease, background-color 300ms cubic-bezier(0.65 , 0.2 , 0.25 , 1), transform 300ms ease, color 300ms ease;
  color: #171717;
  font-size: 14px;
  line-height: 1.111em;
  font-weight: 400;
  text-align: center;
  text-decoration: none;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  cursor: pointer;
  /*font-weight: bolder;*/
  width: 200px;
  height: 50px;
}










